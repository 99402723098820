<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <span class="modal-card-title">Fulfilment for batchjob {{batchJobId}}</span>
        <button class="delete" aria-label="close" v-on:click="close"></button>
      </header>
      <section class="modal-card-body">
        <!-- Content ... -->
        <table class="table is-fullwidth is-size-7" v-if="Fulfilment">
          <thead>
            <tr>
              <th>SId</th>
              <th>Transaction Date</th>
              <th>Code</th>
              <th>Product</th>
              <th>Recipient</th>
              <th>Billing</th>
              <th>Tags</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(f, index) in Fulfilment" :key="index">
              <td>
                <router-link title="Find this order" :to="'/view-user-details/' + f.Id ">{{f.Id }}</router-link>
              </td>
              <td>{{ f.DataObject.meta_info.transaction_datetime}}</td>
              <td>{{ f.FourLetterCode }}</td>
              <td>
                <span>
                  <b>Price</b>:
                  <span>&pound;{{f.DataObject.products_info.offer_price}}</span>
                </span>
                <br />
                <span>
                  <b>Method</b>:
                  <span>{{ f.DataObject.products_info.payment_method}}</span>
                </span>
                <br />
                <span>
                  <b>Desc</b>:
                  <span v-html="f.DataObject.products_info.offer_description"></span>
                </span>
                <br />
              </td>
              <td v-html="getDeliveryAddress(f)"></td>
              <td v-html="getBillingAddress(f)"></td>
              <td>
                <p class="field">
                  <span class="tag">
                    Gift:&nbsp;
                    <b>{{f.DataObject.gift ? "Yes" : "No" }}</b>
                  </span>
                </p>
                <p class="field">
                  <span class="tag">
                    JMag:&nbsp;
                    <b>{{f.DataObject.other.is_JMAG ? "Yes" : "No" }}</b>
                  </span>
                </p>
              </td>
              <td>
                <span class="tag is-success">{{ f.StatusString }}</span>
              </td>
            </tr>
            <!-- <tr v-for="(l, index) in batchJob.Logs" :key="index">
              <td>{{l.CreatedAt}}</td>
              <td>
                <span class="tag is-info">{{l.TypeAsString}}</span>
              </td>
              <td :title="l.Data" style="word-break:break-word">{{l.Data.Message}}</td>
              <td>
                <span v-if="l.StatusAsString == 'Success'" class="tag is-success">Success</span>
                <span v-else class="tag is-warning">{{l.StatusAsString}}</span>
              </td>
            </tr>-->
          </tbody>
        </table>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "batchJobFulfilmentTable",
  props: {
    batchJobId: null
  },
  data() {
    return {
      APIUrl: process.env.VUE_APP_APIURL,
      Fulfilment: null
    };
  },
  created() {
    var url = this.apiUrlGetBatchJobById(this.batchJobId);

    this.axios.get(url).then(response => {
      this.Fulfilment = response.data;
    });
  },
  methods: {
    close: function() {
      //emit close!
      this.$emit("close");
    },
    apiUrlGetBatchJobById(id) {
      return this.APIUrl + "/batch-jobs/" + id + "/fulfilment";
    },
    getDeliveryAddress(f) {
      if (
        f.DataObject.recipient_contacts.recipient_contact_postcode &&
        f.DataObject.recipient_contacts.recipient_contact_address1
      ) {
        return this.getAddress(f, "recipient_contact");
      } else {
        return this.getAddress(f, "billing_contact");
      }
    },
    getBillingAddress(f) {
      return this.getAddress(f, "billing_contact");
    },
    getAddress(f, type) {
      var parentObjectName = type;
      var childPrefix = type;

      //fix odd naming conventions
      if (parentObjectName == "recipient_contact") parentObjectName += "s";

      var name =
        f.DataObject[parentObjectName][childPrefix + "_title"] +
        " " +
        f.DataObject[parentObjectName][childPrefix + "_first_name"] +
        " " +
        f.DataObject[parentObjectName][childPrefix + "_last_name"];

      var arryAddress = [
        name,
        f.DataObject[parentObjectName][childPrefix + "_address1"],
        f.DataObject[parentObjectName][childPrefix + "_address2"],
        // f.DataObject.recipient_contacts.recipient_contact_address3,
        f.DataObject[parentObjectName][childPrefix + "_town"],
        f.DataObject[parentObjectName][childPrefix + "_county"],
        // f.DataObject.recipient_contacts.recipient_contact_country,
        f.DataObject[parentObjectName][childPrefix + "_postcode"]
      ];

      var filtered = arryAddress.filter(function(el) {
        return el != null && el !== "";
      });

      return filtered.join(" <br> ");
    }
  }
};
</script>
